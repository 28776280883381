globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"a7882d0a58bb9a015c97b4b48f069c137a6e71b6"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN || "",
    sampleRate: 0.25,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    denyUrls: [
        "prebid",
        "cdn-cgi",
        "chrome-extension",
        "safari-extension",
        "yieldmo.com",
        "flashtalking.com",
        "amazon-adsystem",
        "yahoo.com",
        "sys.com",
        "doubleclick.net",
        "3lift.com",
        "openx.net",
        "casalemedia.com",
        "smartadserver.com",
        "adnxs.com",
        "google-analytics.com",
        "bidswitch.net",
        "ipify.org",
        "sascdn.com",
        "js7k.com",
        "githubusercontent.com",
        "raw.githubusercontent.com",
        "moatads.com",
        "yimg.com",
        "media.net",
        "adform.net",
        "media.innity.net",
    ],
    ignoreErrors: [
        "prebid",
        "reading '$el'",
        "reading 'focus'",
        "Blocked a restricted frame",
        "getBoundingClientRect",
        "evaluating 'window.open",
        "Custom cursors with size greater than",
        "Network Error",
        "hostname could not be found",
        "'active' of undefined",
        "Load failed",
        "e is null",
        "$el",
        "Cannot read properties of undefined (reading",
        "Custom cursors with size greater",
        "ReportingObserver",
        "ResizeObserver",
        "window.open",
        "chrome-extension",
        "Cannot read property 'focus' of null",
        "Non-Error promise rejection captured with value",
        "Non-Error promise rejection captured with keys",
        "Failed to fetch",
        "scrollX",
        "Failed to read the 'sessionStorage' property from 'Window'",
        "getViewportGeometry",
        "NetworkError when attempting to fetch resource",
        "Cannot read property 'disconnect' of null",
        "Node.appendChild: Cannot add children to a Comment",
        "timeout of 0ms exceeded",
        "Request aborted",
        "gapi is not defined",
    ],
});
